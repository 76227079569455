.noscroll {
  overflow-y: hidden;
  margin-right:17px;
  @media (max-width: $tab) {
    margin-right:0;
  }
}

.display-inline{
  display: inline;
}
.display-none{
  display:none;
}
.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

.italic {
  font-style: italic;
}


.justify-center{
  justify-content: center;
}

.hide-mobile{
  @media (max-width: $mob) {
    display:none !important;
  }
}

.color-red {
  color: $red !important;
}
.color-orange{
  color: $orange !important;
}
.color-green {
  color: $green !important;
}
.color-white {
  color: $white !important;
}

.w-100{
  width:100%;
}
.h-100{
  height: 100%;
}

.flex{
  display:flex;
} 

.align-items-center{
  align-items: center;
}

.mt-xl{
  margin-top:85px;
  @media (max-width: $mob) {
    margin-top: 50px;
  }
}
.mb-xl {
  margin-bottom: 85px;
  @media (max-width: $mob) {
    margin-bottom: 50px;
  }
}

.mb-xs {
  margin-bottom: 20px;
}

.mt-l {
  margin-top: 50px;
}
.mb-l{
  margin-bottom: 50px;
}
.mt-m {
  margin-top:35px;
}
.mt-s {
  margin-top:28px;
}
.mt-xs {
  margin-top: 20px;
}
.mt-xxs{
  margin-top: 10px;
}



.fs-14{
  font-size:14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-17{
  font-size:17px;
}
.fs-18 {
  font-size:18px;
}
.fs-19 {
  font-size:19px;
}
.fs-22 {
  font-size: 23px;
}
.fs-23{
  font-size: 23px;
}

.fw-400{
  font-weight:400 !important;
}


.bg-redlight{
  background:$bg-footer;
}


.pojo-a11y-toolbar-toggle{
  position: fixed;
  bottom: 0;
  left: 0;
}
#pojo-a11y-toolbar.pojo-a11y-toolbar-left .pojo-a11y-toolbar-toggle {
  left: 180px;
}


#pojo-a11y-toolbar.pojo-a11y-toolbar-left{
  left: 0;
  bottom: 0;
  top: auto !important;
}

#pojo-a11y-toolbar.pojo-a11y-toolbar-left.pojo-a11y-toolbar-open {
  left: -180px;
  bottom: 0;
  top: auto !important;
}

.wp-block-embed iframe{
  display: block;
  margin:auto;
  border:3px solid $orange;
  border-radius: 28px;
  overflow: hidden;
}
.wp-element-caption{
  text-align: center;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items li.pojo-a11y-toolbar-item a,
#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay p.pojo-a11y-toolbar-title{
  text-decoration:none;
}