$white:         #ffffff;
$black:         #000000;
$grey:          #585858;
$grey-light:    #C9C9C9;
$grey-dark:     #2F2F2F;

$bg-contrast:   #FAF8F0;
$bg-footer:     #f9efea;

$red:           #C94D1D;
$orange:        #E25A24;
$yellow:        #F1C219;
$green:         #2B8177;
$blue:          #1976DC;




