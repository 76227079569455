.team-list{
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    gap: 50px 100px;
    margin-top:50px;
    @media (max-width: $tab) {
        gap: 50px 60px;
    }
    @media (max-width: $mob) {
        gap: 40px 25px;
    }
    .team-list__item{
        .item__details{
            z-index:11;
            position: fixed;
            top:0;
            left:0;
            display:flex;
            justify-content: center;
            align-items: center;
            width:100%;
            height:100%;
            background:rgba($black, 0.68);
            opacity: 0;
            visibility: hidden;
            transition: all 0.2s;
            &.active{
                opacity: 1;
                visibility: visible;
                .details__content {
                    transform: scale(1);
                }
            }
            .details__content{
                position: relative;
                display:flex;
                width: 730px;
                padding: 55px 45px 35px 45px;
                box-sizing: border-box;
                background: $white;
                transform: scale(0);
                transition: all 0.2s;
                @media (max-width: $mob) {
                    width:100%;
                    height:100%;
                    flex-direction: column;
                    align-items: center;
                    padding: 15px;

                }
                .content__close{
                    position: absolute;
                    top:15px;
                    right:15px;
                    cursor:pointer;
                    transition: all 0.2s;
                    &:hover{
                        transform: rotate(90deg);
                    }
                }
                .content__text{
                    width: calc(100% - 212px);
                    padding-left:34px;
                    box-sizing: border-box;
                    @media (max-width: $mob) {
                        width:100%;
                        margin-top:30px;
                        padding-left:0;
                    }
                }
            }
        }
    }
}