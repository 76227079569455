.popup-rdv{
    z-index: $z-popup;
    position:fixed;
    top:0;
    left:0;
    display:none;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;
    background:rgba($black, 0.6);
    .popup-rdv__content{
        position:relative;
        max-width: 900px;
        width: 90%;
        margin: auto;
        opacity: 0;
        visibility:hidden;
        transition: all 0.2s;
        .content__close{
            position:absolute;
            top:10px;
            right:10px;
            color:$black;
            font-size: 30px;
            z-index: 1;
            cursor:pointer;
        }
    }
    &.active {
        display:flex;
        .popup-rdv__content {
            opacity: 1;
            visibility: visible;
        }
    }
}