.projet-en-recherche-details{
    display:flex;
    padding:32px 37px;
    background:$bg-contrast;
    border-radius:28px;
    @media (max-width: $desksm) {
        padding: 32px 0;
    }
    @media (max-width: $tab) {
        flex-direction: column;
    }
    @media (max-width: $tab) {
        padding: 15px 0;
    }
    .projet-en-recherche-details__item{
        max-width: 22%;
        padding:0 30px;
        box-sizing: border-box;
        border-right:1px solid $grey-light;
        @media (max-width: $tab) {
            margin-bottom:20px;
            padding-bottom:20px;
            border-right: 0;
            border-bottom: 1px solid $grey-light;
        }
        @media (max-width: $mob) {
            margin-bottom: 15px;
            padding:0 15px 15px 15px;
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child{
            border:0;
            padding-right:0;
            @media (max-width: $tab) {
                margin-bottom: 0;
                padding-bottom: 0;
            }
            @media (max-width: $mob) {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
        .item__title{
            font-weight: 500;
            i{
                color:$orange;
            }
            
        }
        .item__content{
            margin-top:5px;
        }
    }
}