.fiches-methodo-list{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card-fiche-methodo{
        width: calc(50% - 55px);
        margin:34px 0;
        @media (max-width: $desksm) {
            width: calc(50% - 25px);
        }
        @media (max-width: $tab) {
            width: calc(50% - 15px);
        }
        @media (max-width: $mob) {
            width:100%;
        }
        .card-fiche-methodo__content{
            cursor:pointer;
            .content__top{
                position: relative;
                border: 3px solid $orange;
                border-radius: 22px;
                overflow: hidden;
                .top__img{
                    width: 100%;
                    height: 260px;
                    object-fit: cover;
                    @media (max-width: $mob) {
                        height: 230px !important;
                    }
                }
                .top__title{
                    position: absolute;
                    top:0;
                    left:0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width:100%;
                    height:100%;
                    background:rgba($black, 0.6);
                    h2{
                        width:calc(100% - 40px);
                        padding:20px;
                        font-size: 30px;
                        font-weight: 500;
                        color:$white;
                        text-align:center;
                        @media (max-width: $tab) {
                            font-size: 24px;
                        }
                    }
                }
                .top__category{
                    position: absolute;
                    top: 24px;
                    left: 0;
                    display: inline-flex;
                    padding: 4px 18px;
                    background: #fff;
                    border-radius: 0 5px 5px 0;
                    color: #000;
                    font-size: 15px;
                    font-weight: 500;
                }
            }

            .content__text{
                max-width:420px;
                margin: 15px auto 0 auto;
                text-align:center;
                .text__title{
                    font-size:19px;
                    font-weight:500;
                    color:$orange;
                }
                .text__link{
                    margin-top:10px;
                    .link-black{
                        font-weight: normal !important;
                    }
                }
            }
        }

        .card-fiche-methodo__details {
            z-index: 11;
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: rgba($black, 0.68);
            opacity: 0;
            visibility: hidden;
            transition: all 0.2s;
        
            &.active {
                opacity: 1;
                visibility: visible;
        
                .details__content {
                    transform: scale(1);
                }
            }
            .details__content {
                position: relative;
                display: flex;
                width: 1060px;
                padding: 55px 45px 35px 45px;
                box-sizing: border-box;
                background: $white;
                transform: scale(0);
                transition: all 0.2s;

                @media (max-width: $mob) {
                    width: 100%;
                    height: 100%;
                    flex-direction: column;
                    align-items: center;
                    padding: 15px;

                }

                .content__close {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    cursor: pointer;
                    transition: all 0.2s;

                    &:hover {
                        transform: rotate(90deg);
                    }
                }

                .content__image{
                    width: calc(100% - 420px);
                    object-fit: cover;
                    border: 3px solid $orange;
                    border-radius: 22px;
                    @media (max-width: $mob) {
                        width: 100%;
                    }
                }

                .content__text {
                    width: 420px;
                    padding-left: 60px;
                    box-sizing: border-box;

                    @media (max-width: $mob) {
                        width: 100%;
                        margin-top: 30px;
                        padding-left: 0;
                    }
                    .text__title{
                        font-size: 20px;
                        font-weight: 500;
                        color: $orange;
                    }
                    .text__intro{
                        margin-top:12px;
                    }
                    .text__link {
                        margin-top: 12px;
                    }
                }
            }
        }

        

    }
}