.hero{
    background-color:$bg-contrast;
    background-image: url(../images/hero@2x.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: calc(50% + 320px);
    @media (max-width: $mob) {
        background-position: bottom;
        background-size: 90%;
    }

    .hero__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:40%;
        height: 524px;
        @media (max-width: $mob) {
            width: 100%;
            height: auto;
            padding: 50px 0 68vw 0;
        }
        .content__title{
            font-size:52px;
            font-weight:600;
            @media (max-width: $mob) {
                font-size: 38px;
            }
        }
        .content__button{
            margin-top:20px;
        }
    }
}