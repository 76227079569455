.team-presentation{
    width:212px;
    @media (max-width: $mob) {
        width: 140px;
    }
    &--pointer {
        cursor: pointer;
    }
    .team-presentation__image {
        border-radius: 22px;
        border: 3px solid $orange;
        box-sizing: border-box;
        @media (max-width: $mob) {
            width:140px;
            height:140px;
        }
    }
    .team-presentation__text {
        text-align: center;
        .text__name {
            margin-top: 18px;
            color: $orange;
            font-size: 20px;
            font-weight: 500;
            @media (max-width: $tab) {
                margin-top: 15px;
            }
            @media (max-width: $mob) {
                margin-top: 10px;
                font-size: 18px;
            }
        }
        .text__links{
            margin-top: 10px;
            a{
                color: $black;
                font-size: 28px;
                text-decoration: none;
                transition: all 0.2s;
                &:hover{
                    color: $orange;
                }
            }
        }
    } 
}