.form{
    max-width:900px;
    margin:50px auto 0 auto;
}
.form-line{
    margin-bottom:25px;
    &--2{
        display:flex;
        justify-content: space-between;
        @media (max-width: $mob) {
            display:block;
        }
        .form-line__input{  
            width: calc(50% - 15px);
            @media (max-width: $mob) {
                width: 100%;
                margin-bottom: 25px;
            }
        }
    }
    
    .wpcf7-spinner{
        display: block;
        margin: 10px auto;
    }
    
    p{
        margin:0;
    }
    label{
        display:block;
        margin-bottom: 5px;
        font-weight:600;
    }
    input[type="text"],
    input[type="email"],
    select
    {
        width:100%;
        height:40px;
        padding:0 10px;
        box-sizing: border-box;
        font-size:16px;
    }
}