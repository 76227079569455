.block-share{
    padding:22px 0;
    background:$bg-contrast;

    .block-share__content{
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:19px;
    }
}