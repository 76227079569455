.accompagnement-chercheurs{
    padding:32px 0;
    background:$yellow;
    .accompagnement-chercheurs__content{
        max-width: 1020px;
        margin:auto;
    }
    .content__title{
        display:flex;
        align-items: center;
        max-width:600px;
        margin:auto;
        font-size: 40px;
        font-weight: 700;
        text-align:center;
        @media (max-width: $mob) {
            flex-direction: column;
            font-size: 30px;
        }
        .title__picto{  
            margin-right:33px;
            @media (max-width: $mob) {
                margin:0 0 25px 0;
            }
        }
    }
}