.pill{
    display:inline-flex;
    align-items: center;
    height:32px;
    padding:0 11px;
    background-color: $orange;
    border-radius:5px;
    color:$white;
    font-size:18px;
    font-weight:600;
    text-align: center;
    &--red{
        background-color: $red;
    }
    &--blue {
        background-color: $blue;
    }
    &--yellow {
        background-color: $yellow;
    }
    &--green {
        background-color: $green;
    }
}