.image-border{
    box-sizing: border-box;
    border-radius: 35px;
    border: 2px solid $orange;
    &--red {
        border-color: $red;
    }

    &--blue {
        border-color: $blue;
    }

    &--yellow {
        border-color: $yellow;
    }

    &--green {
        border-color: $green;
    }
}