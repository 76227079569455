.button{
    display:inline-flex;
    align-items: center;
    justify-content: center;
    min-height:30px;
    box-sizing: border-box;
    padding:2px 18px;
    background: $yellow;
    border:1px solid $yellow;
    border-radius:30px;
    font-size:14px;
    color:$black;
    text-decoration:none;
    text-align:center;
    font-weight:600;
    transition: all 0.2s;
    cursor:pointer;
    &:hover{
        background:$orange;
        border-color: $orange;
    }

    &--w100{
        width:100%; 
    }
    &--orange {
        background: $orange;
        border: 1px solid $orange;
        color:$white;
        &:hover {
            background: $yellow;
            border-color: $yellow;
            color: $black;
        }
        &:disabled {
            &:hover{
                background: $orange;
                border: 1px solid $orange;
                color: $white;
            }
        }
    }

    &--m {
        min-height: 30px;
        padding: 4px 24px;
        font-size: 17px;
    }

    &--large{
        min-height:40px;
        padding:4px 24px;
        font-size:18px;
    }
    &--xlarge {
        min-height: 40px;
        padding: 4px 24px;
        font-size: 21px;
    }

    &--outline-black {
        background: transparent;
        border-color: $black;
    }
    
    &--outline-orange{
        background: transparent;
        border-color: $orange;
        color:$orange;
        &:hover {
            color: $black;
        }
    }
    &:disabled{
        opacity: 0.6;
        cursor:default;
    }
}