.contact-text-1{
    padding:55px 120px;
    background:$bg-contrast;
    @media (max-width: $tab) {
        padding: 30px 60px;
    }
    @media (max-width: $mob) {
        padding: 15px 15px;
    }
    ul{
        li{
            font-size:24px;
            @media (max-width: $tab) {
                font-size: 21px;
            }
            @media (max-width: $mob) {
                font-size: 18px;
            }
        }
    }
}

.contact-text-2{
    font-size: 24px;
    @media (max-width: $tab) {
        font-size: 21px;
    }

    @media (max-width: $mob) {
        font-size: 18px;
    }
}