.block-partenaire{
    padding:55px 0;
    background:rgba($green, 0.92);
    color:$white;
    .block-partenaire__content{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .content__text{
            width:384px;
            @media (max-width: $tab) {
                width: 100%;
            }
        }
        .content__logos{
            display: flex;
            justify-content: flex-end;
            flex-wrap:wrap;
            width: calc(100% - 384px);
            @media (max-width: $tab) {
                justify-content: center;
                width:100%;
               
            }
            img{
                margin-left:60px;
                @media (max-width: $desksm) {
                    margin-left:25px;
                }
                @media (max-width: $mob) {
                    margin-left:0;
                    margin-top: 25px;
                }
            }
        }
    }
}