.map {
    position: relative;
    display:flex;
    overflow: hidden;
    @media (max-width: $tab) {
        display:block;
    }
    &__image {
        max-width: 650px;
        width: 100%;
        height: auto;
        @media (max-width: $tab) {
            width: 100%;
            height:auto;
        }
    }
}



.map__image svg {
    width: 100%;
    height: 650px;
    @media (max-width: $tab) {
        width: 100%;
        height:auto;
    }
}

.map__image path {
    fill: transparent;
    stroke: $orange;
    stroke-width: 2px;
    transition: fill 0.3s;
    cursor: pointer;
}

.map__image .is-active path {
    fill: $orange;
}

.map__image .is-active path {
    fill: $orange;
}

/* Liste
   ========================================================================== */
.map__list {
    width: 45%;
    padding-left: 110px;
    @media (max-width: $tab) {
        width: 100%;
        padding-left: 0;
    }
}

.map__list ul,
.map__list li {
    margin: 0;
    padding: 0;
    list-style: none;
}
.map__list .list__cities {
    
    height: 300px;
    overflow-y: auto;
    @media (max-width: $tab) {
        height: auto;
    }
    & > div{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
}

/*
.map__list .list__cities {
    display: none;
    width: 100%;
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
    cursor: pointer;
}
*/

.map__list .cities__item{
    display: none;
    align-self: flex-start;
    margin: 5px 5px 0 0 ;
    width: 145px;
    img{
        width:100%;
        height:auto;
    }

}

.map__list .cities__item.is-active {
    display:flex;
}
