.main-title{
    padding:20px 0;
    background:$green;
    color:$white;
    text-align:center;
    
    .main-title__title{
        font-size: 32px;
    } 
    .main-title__category {
        display: inline-flex;
        padding: 4px 18px;
        margin-bottom:10px;
        background: #fff;
        border-radius: 5px;
        color: #000;
        font-size: 17px;
        font-weight: 500;
        cursor: pointer;
    }
    .main-title__date{
        margin-top:5px;
        font-size:18px;
        font-weight:500;
    }
}