.page404{
    
    background:$bg-contrast;
    .wrap{
        position:relative;
        display:flex;
        align-items: center;
        @media (max-width: $tab) {
            flex-direction: column-reverse;
            
        }
    }
    .page404__img{
        width:56vw;
        max-width:853px;
        margin-left:-30px;
        @media (max-width: $tab) {
            width: 100%;
            margin:auto;
            margin-top:40px;
        }
    }   
    .page404__content{
        position: absolute;
        right: 30px;
        max-width:400px;
        @media (max-width: $tab) {
            position: relative;
            right: auto;
            padding-top:40px;
        }
        .content__title{
            margin-bottom: 40px;
            color:$green;
            font-weight:600;
            font-size:49px;
        }
        p{
            font-weight: 600;
            font-size: 32px;
            line-height:1;
        }
        .content__link{
            margin-top: 30px;
        }
    }
}