.header{
    position: sticky;
    top:0;
    background: $white;
    z-index: $z-menu;
    .header__top{
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        @media (max-width: $mob) {
            height: 60px;
        }
        .top__right{
            position:absolute;
            right:0;
            
            @media (max-width: $desksm) {
                display:none;
            }
            .right__lang{
                margin-bottom:10px;
                text-align: center;
                @media (max-width: $desksm) {
                    margin-top:30px;
                    margin-bottom: 15px;
                }
                ul{
                    list-style: none;
                    display:flex;
                    justify-content: center;
                    gap: 0 8px; 
                    margin:0;
                    padding:0;
                    li{
                        margin: 0;
                        a {
                            font-size: 12px;
                            text-decoration: none;
                            text-transform: uppercase;
                            @media (max-width: $desksm) {
                                font-size: 16px;
                            }
                        }
                        &.current-lang{
                            a {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        .top__logo {
            @media (max-width: $mob) {
                width:110px;
            }
        }
        .top__menu{
            position: absolute;
            right: 0;
            display:none;
            font-size:26px;
            cursor:pointer;
            @media (max-width: $desksm) {
                display: block;
            }
            .menu__open{

            }
            .menu__close{
                display:none;
            }
            &.open{
                .menu__open {
                    display: none;
                }
                .menu__close {
                    display: block;
                }
            }
        }
    }
    .header__menu{
        display: flex;
        align-items: center;
        height:68px;
        @media (max-width: $desksm) {
            position: fixed;
            display:block;
            top: 90px;
            right:-100%;
            height:calc(100% - 90px);
            transition: 0.2s;
            background:$white;
            overflow-y: auto;
            
            &.open{
                right: 0;
            }
        }
        @media (max-width: $mob) {
            top: 60px;
            height: calc(100% - 60px);
        }
        .menu__lang{
            display:none;
            margin-bottom: 10px;
            text-align: center;
            @media (max-width: $desksm) {
                display: block;
                margin-top: 30px;
                margin-bottom: 15px;
            }
            ul {
                list-style: none;
                display: flex;
                justify-content: center;
                gap: 0 8px;
                margin: 0;
                padding: 0;
                li {
                    margin: 0;
                    a {
                        font-size: 12px;
                        text-decoration: none;
                        text-transform: uppercase;
        
                        @media (max-width: $desksm) {
                            font-size: 16px;
                        }
                    }
                    &.current-lang {
                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }           
        }
        .button-contact-mobile{
            display:none;
            @media (max-width: $desksm) {
                display:block;
                padding-top:15px;
            }
        }
        .menu{
            display: flex;
            align-items: center;
            justify-content: space-between;
            list-style: none;
            padding: 0;
            margin:0 -11px;
            @media (max-width: $desksm) {
                margin: 0;
                padding:15px 0;
                display: block;
            }
            li{
                position: relative;
                margin:0;
                padding:0 11px;
                font-size:16px;
                @media (max-width: $desksm) {
                    padding: 0;
                    margin-top:15px;
                    font-weight:500;
                }
                a{
                    color:$black;
                    text-decoration: none;
                    transition: all 0.2s;;
                    &:hover{
                        color:$orange;
                    }
                }
            }
            .menu-item-has-children{
                &:after{
                    content: "\f078";
                    margin-left:5px;
                    font-weight: 900;
                    font-family: Font Awesome\ 6 Free;
                    color:$orange;
                    @media (max-width: $desksm) {
                        display:none;
                    }
                }
                &:hover{
                    .sub-menu {
                        opacity: 1;
                        visibility: visible;
                    }
                }                
                .sub-menu {
                    z-index:$z-menu;
                    position: absolute;
                    top:100%;
                    left:0;
                    min-width:100%;
                    list-style: none;
                    background-color: $white;
                    padding:10px 0 0 0;
                    border:1px solid $grey-light;
                    border-top:0;
                    border-radius:0 0 10px 10px;
                    opacity: 0;
                    visibility:hidden;
                    transition: all 0.2s;
                    @media (max-width: $desksm) {
                        position:relative;
                        top:auto;
                        left:auto;
                        opacity: 1;
                        visibility: visible;
                        padding: 0;
                        border: 0;
                        border-radius:0;
                    }
                    li {
                        padding:0;
                        border-bottom:1px solid $grey-light;
                        @media (max-width: $desksm) {
                            font-weight:400;
                            border:0;
                            margin-top:0;
                        }
                        &:last-child{
                            border:0;
                        }
                        a{
                            display: block;
                            padding: 10px 11px;
                            @media (max-width: $desksm) {
                                padding: 5px 11px;
                            }
                        }
                    }

                }
            }
            
        }
    }
} 