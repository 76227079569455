.block-arrow{
    .block-arrow__content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $mob) {
            flex-direction: column;
            gap: 30px;
        }
        .content__part{
            width: calc(50% - 90px);
            @media (max-width: $tab) {
                width: calc(50% - 40px);
            }
            @media (max-width: $mob) {
                width: 100%;
            }
            &--image{
                @media (max-width: $mob) {
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .content__arrow{
            @media (max-width: $mob) {
                display:none;
            }
        }
    }
}