.materiel{
    .materiel__title{
        padding:22px 0;
        background: $bg-contrast;
        .title__text{
            font-size: 28px;
            @media (max-width: $mob) {
                font-size: 24px;
            }
        }
        
    }
}