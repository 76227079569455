.more-posts{
    .more-posts__title{
        display: flex;
        justify-content: space-between;
        @media (max-width: $tab) {
            flex-direction: column;
            text-align:center;
            align-items: center;
        }
    }
}