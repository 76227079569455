.scroll-top{
    position: fixed;
    bottom:20px;
    right:20px;
    display:flex;
    justify-content: center;
    align-items: center;
    width:74px;
    height:74px;
    border-radius:74px;
    background: rgba($orange, 0.25);
    @media (max-width: $mob) {
        display:none;
    }
}