.block-consortium{
    .block-consortium__labos{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        max-width:930px;
        margin:45px auto 0 auto;
    }
}