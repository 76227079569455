.panorama{
    margin-bottom:-85px;
    @media (max-width: $mob) {
        margin-bottom: 0;
    }
    .panorama__content{
        display:flex;
        @media (max-width: $mob) {
            display:block;
        }
        .content__filters{
            position: sticky;
            top: 158px;
            width:320px;
            height:calc(100vh - 158px);
            overflow-y:scroll;
            padding: 20px 30px 20px 0;
            box-sizing:border-box;
            border-right:1px solid $grey-light;
            @media (max-width: $desksm) {
                height: calc(100vh - 90px);
                top: 90px;
            }
            @media (max-width: $tab) {
                width: 260px;
                padding: 20px 20px 20px 0;
            }
            @media (max-width: $mob) {
                z-index:$z-filters;
                position:fixed;
                left:0;
                top:100%;
                width:100%;
                height: 100vh;
                padding: 20px 15px 40px 15px;
                background: $white;
                transition:all 0.2s;
                &.active{
                    top: 0;
                    .filters__valid-mob {
                        display: flex;
                    }
                }
            }
            .filters__header{
                display:flex;
                justify-content: space-between;
                margin-bottom:30px;
                .header__title{
                    font-size: 21px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                .header__reset{
                    cursor:pointer;
                    transition: all 0.2s;
                    &:hover{
                        color:$orange;
                    }
                }
            }
            .filters__valid-mob{
                display:none;
                @media (max-width: $mob) {
                    position:fixed;
                    bottom:0;
                    left:0;
                    align-items: center;
                    justify-content: center;
                    width:100%;
                    height: 50px;
                    background: $orange;
                    color: $white;
                    font-size: 22px;
                    font-weight: 500;
                    text-align: center;
                    text-transform: uppercase;
                    cursor: pointer;
                }
            }
            .filters__category{
                margin-bottom:10px;
                .category__title{
                    display:flex;
                    align-items: center;
                    justify-content: space-between;
                    padding:5px 0;
                    border-top:1px solid $grey-light;
                    border-bottom: 1px solid $grey-light;
                    color: $orange;
                    font-size:17px;
                    font-weight: 700;
                    cursor:pointer;
                    &.hidden{
                        .title__down {
                            transform: rotate(180deg);
                        }
                    }
                    .title__down{
                        width:20px;
                        height:20px;
                        margin-left:10px;
                        background: url(../images/down.svg) no-repeat center;
                        transition: all 0.2s;
                    }
                }
                .category__list{
                    margin:10px 0 30px 0;
                    padding:0;
                    list-style:none;
                    .list__item{
                        display:flex;
                        align-items:center;
                        margin-bottom:5px;
                        font-size:13px;
                        .item__label{
                            margin-left:5px;
                            cursor: pointer;
                        }
                        .item__input{
                            cursor:pointer;
                            margin:0;
                        }
                    }
                }
            }
        }
        .content__show-filters-mob{
            display:none;
            @media (max-width: $mob) {
                position:sticky;
                top: 60px;
                z-index: 1;
                display:flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                background:$white;
                border-bottom:1px solid $grey-light;
                color: $orange;
                font-size:22px;
                font-weight:500;
                text-align: center;
                text-transform: uppercase;
                cursor:pointer;
            }
        }
        .content__results{
            position:relative;
            width:calc(100% - 320px);
            padding:50px 0 0 80px;
            box-sizing: border-box;
            @media (max-width: $tab) {
                width: calc(100% - 260px);
                padding: 40px 0 0 30px;
            }
            @media (max-width: $mob) {
                width:100%;
                padding: 30px 0 0 0;
            }
            .results__contact{
                display:flex;
                align-items: center;
                justify-content: center;
                gap: 17px;
                width:100%;
                padding:13px 70px;
                box-sizing: border-box;
                margin:0 0 50px 0;
                background:rgba($green, 0.15);
                border-radius: 15px;
                @media (max-width: $tab) {
                    flex-direction: column;
                    padding: 20px 15px;
                }
                .contact__picto{
                    img{
                        width: 91px;
                        @media (max-width: $tab) {
                            width: 60px;
                        }
                    }
                }
                .contact__text{
                    font-size:20px;
                    color:$green;
                    @media (max-width: $tab) {
                        text-align: center;
                    }
                }
                .contact__button{
                    width:245px;
                    text-align:center;
                }
            }
            .results__list{
                position:relative;
                .list__no-result {
                    position: absolute;
                    top: 50px;
                    left: 0;
            
                    @media (max-width: $tab) {
                        top: 40px;
                    }
            
                    @media (max-width: $mob) {
                        top: 30px;
                    }
                }
            
                .list__item {
                    display: flex;
                    position: relative;
                    margin-bottom: 40px;
                    background: $white;
            
                    @media (max-width: $tab) {
                        display: block;
                    }
            
                    .item__map {
                        width: 220px;
                        margin-right:30px;
                        .map__img {
                            max-height: 223px;
                            &--ile{
                                max-width:100px;
                                margin:auto;
                            }
                        }

                    }
            
                    .item__text {
                        width: calc(100% - 250px);
            
                        @media (max-width: $tab) {
                            width: 100%;
                        }
            
                        .text__title {
                            color: $green;
                            font-size: 21px;
                            font-weight: 700;
                            text-transform: uppercase;
                        }
            
                        .text__list {
                            margin-top: 15px;
                            padding: 0;
                            list-style: none;
            
                            li {
                                position: relative;
                                padding-left: 17px;
            
                                &::before {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 9px;
                                    left: 0;
                                    width: 7px;
                                    height: 7px;
                                    border-radius: 7px;
                                    background: $green;
                                }
            
                                font-size: 18px;
            
                                a {
                                    color: $black;
                                    text-decoration: none;
            
                                    &:hover {
                                        text-decoration: underline;
                                    }
            
                                    span {
                                        font-size: 16px;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
}