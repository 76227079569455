.ressources{
    max-width:810px;
    margin: 38px auto 0 auto;
    .ressources__list{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        gap:38px 60px;
        margin-top:38px;
        @media (max-width: $mob) {
            gap: 38px 20px;
        }
        .list__item{
            display:block;
            width:170px;
            text-decoration: none;
            &:hover{
                .item__text {
                    color:$orange;
                }
            }
            .item__picto{
                width:110px;
                margin:auto;
            }
            .item__text{
                display: block;
                margin-top:15px;
                color:$black;
                font-weight:700;
                text-align:center;
                transition: all 0.2s;
            }
        }
    }
}