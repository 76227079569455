.white-rounded{
    box-sizing: border-box;
    padding:30px 45px;
    background:$white;
    border-radius:28px;
    text-align: center;
    @media (max-width: $mob) {
        margin-bottom: 20px;
        padding: 30px 45px;
        height:auto !important
    }
    .white-rounded__title{
        max-width: 330px;
        margin:auto;
    }
    .white-rounded__text{
        font-size:23px;
        @media (max-width: $mob) {
            font-size: 20px;
        }
    }
}