.liste-diffusion{
    padding:48px 0;
    .liste-diffusion__form{
        display:flex;
        align-items: center;
        max-width:830px;
        margin: auto;
        .form__input{
            width:calc(100% - 130px);
            height:58px;
            padding:0 20px;
            box-sizing: border-box;
            background: #ffffff;
            border-radius: 9px;
            border: 1px solid $grey-light;
            font-family: $font-1;
            font-size: 27px;
            font-weight: 500;
            @media (max-width: $mob) {
                width: calc(100% - 110px);
                height: 48px;
                font-size: 22px;
            }
        }
        .form__submit{
            appearance:none;
            width:124px;
            height:58px;
            margin-left:6px;
            background:$orange;
            border-radius: 9px;
            border:0;
            color:$white;
            font-family: $font-1;
            font-size: 22px;
            font-weight: 500;
            cursor:pointer;
            transition: all 0.2s;
            &:hover{
                color:$black;
            }
            @media (max-width: $mob) {
                width: 104px;
                height: 48px;
                font-size: 22px;
            }
        }
        .wpcf7{
            width:100%;
        }
        .wpcf7-spinner{
            display:none;
        }
        p{
            margin:0;
        }
    }
}