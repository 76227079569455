
.card{
    position:relative;
    height: auto;
    box-sizing: border-box;
    margin-bottom:33px;
    background:$white;
    border:1px solid $grey-light;
    border-radius:20px;
    .card__img{
        display:block;
        width:100%;
        height: 192px !important;
        object-fit: cover;
        border-radius: 19px 19px 0 0;
        @media (max-width: $desksm) {
            height: 210px !important;
        }
        @media (max-width: $tab) {
            height: 250px !important;
        }
        @media (max-width: $mob) {
            height: auto !important;
            aspect-ratio: 3 / 2;
        }
    }
    .card__category{
        position:absolute;
        top:24px;
        left:0;
        display: inline-flex;
        padding:4px 18px;
        background:$white;
        border-radius: 0 5px 5px 0;
        color:$black;
        font-size:15px;
        font-weight:500;
    }
    .card__content{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        height: calc(100% - 192px);
        padding: 20px 17px 25px 17px;
        font-size:16px;
        @media (max-width: $desksm) {
            height: calc(100% - 210px);
        }
        @media (max-width: $tab) {
            height: calc(100% - 250px);
            padding: 20px 15px 20px 15px;
        }
        @media (max-width: $mob) {
            height: auto;
        }
        .content__title{

        }
        .content__date{
            margin-top:14px;
            color:$grey;
            font-size:15px;
            &--event{
                color:$green;
                font-size: 17px;
                font-weight:500;
            }
        }
        .content__text{
            margin:14px 0;
            color:$grey;
            font-size: 16px;
        }
        .content__link{
            margin-top: auto;
        }
    }

    &--wide{
        padding: 32px 25px;
        @media (max-width: $tab) {
            padding: 25px 15px;
        }
        .card__img {
            height: 313px !important;
            object-fit: cover;
            border-radius: 19px;
        
            @media (max-width: $desksm) {
                height: 280px !important;
            }
        
            @media (max-width: $tab) {
                height: 250px !important;
            }
        
            @media (max-width: $mob) {
                height: auto !important;
                aspect-ratio: 16 / 9;
            }
            
            
        }
        .card__content {
            height: calc(100% - 313px);
            padding: 20px 0 1px 0;
            @media (max-width: $desksm) {
                height: calc(100% - 280px);
            }
            @media (max-width: $tab) {
                height: calc(100% - 250px);
            }
            @media (max-width: $mob) {
                height: auto;
            }
        }
        &--no-border {
            padding: 0 0 1px 0;
            border:0;
        }
    }

    &--small {
        padding: 26px 20px;

        @media (max-width: $tab) {
            padding: 20px 15px;
        }

        .card__img {
            height: 140px !important;
            object-fit: cover;
            border-radius: 19px;
            @media (max-width: $mob) {
                height: auto !important;
                aspect-ratio: 16 / 9;
            }


        }

        .card__content {
            height: calc(100% - 140px);
            padding: 15px 0 1px 0;

            @media (max-width: $mob) {
                height: auto;
            }
            .title{
                height:48px;
                overflow: hidden;
                @media (max-width: $mob) {
                    height:none;
                }
            }
            .content__text{
                height: 63px;
                overflow: hidden;
                @media (max-width: $mob) {
                    height: none;
                }
            }

        }

        &--no-border {
            padding: 0 0 1px 0;
            border: 0;
        }
    }
}

.row {
    .card {
        height: calc(100% - 35px);
    }
}