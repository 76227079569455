.breadcrumbs, .yoast-breadcrumbs{
    margin-bottom:35px;
    color:$red;
    font-size:14px;
    font-weight:500;
    a{
        color:$red;
        &:hover{
            text-decoration:none;
        }
    } 
}