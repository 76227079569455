.footer{
    background: $bg-footer;
    .footer__content{
        display:flex;
        justify-content: space-between;
        max-width:1180px;
        padding:67px 0;
        margin: auto;
        @media (max-width: $mob) {
            flex-direction: column;
        }
        
        .content__logos{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 28px;
            width: 178px;
            padding-right:48px;
            margin-right:48px;
            border-right: 1px solid $grey-light;
            @media (max-width: $tab) {
                padding-right: 28px;
                margin-right: 28px;
            }
            @media (max-width: $mob) {
                align-items: center;
                width: 100%;
                margin:0;
                padding: 0;
                border:0;
                
            }
        }

        .content__france2032{
            display: flex;
            align-items:center;
            width: calc(100% - 574px);
            @media (max-width: $tab) {
                width: calc(100% - 454px);
            }
            @media (max-width: $mob) {
                width: 100%;
                margin: 50px 0 0 0;
            }
            .france2032__text{ 
                margin-left:16px;
                color:$grey;
                font-size:16px;
            }
        }

        .content__contact {
            width:200px;
            padding-left: 48px;
            margin-left: 48px;
            border-left: 1px solid $grey-light;
            @media (max-width: $tab) {
                padding-left: 28px;
                margin-left: 28px;
            }
            @media (max-width: $mob) {
                width: 100%;
                margin:40px 0 0 0;
                padding: 0;
                border:0;
            }
            .contact__title{
                text-align:center;
                font-size: 16px;
                font-weight: bold;
                text-transform:uppercase;
            }
            .contact__links{
                font-size: 32px;
                text-align:center;
                a{
                    color: $black;
                    text-decoration: none;
                    transition: color 0.2s;
                    &:hover{
                        color:$orange;
                    }
                }
                
            }
            .contact__button-contact{
                margin-top: 45px;
                @media (max-width: $mob) {
                    margin-top: 30px;
                }
            }
            .contact__button-rdv{
                margin-top:12px;
            }
        }
    }
    .footer__links{
        padding:17px 0;
        background:$white;
        color:$grey;
        font-size:12px;
        text-align:center;
        a{
            color:$grey;
            transition: all 0.2s;
            &:hover{
                color:$orange;
            }
        }
    }
}