.main-wrap {
  width: 100%;
  //max-width: 1920px;
  margin: auto;
}
.wrap {
  width: 100%;
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px;
  box-sizing: border-box;
  @media (max-width: $tab) {
    padding: 0 20px;
  }
  @media (max-width: $mob) {
    padding: 0 15px;
  }
  &--1030 {
    max-width: 1030px;
  }
  &--990{
    max-width: 990px;
  }
}
