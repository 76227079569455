.single-evenement{
    .single-evenement__content{
        display:flex;
        justify-content: space-between;
        @media (max-width: $mob) {
            flex-direction: column-reverse;
        }
        .content__main{
            width: calc(100% - 340px);
            max-width:820px;
            @media (max-width: $tab) {
                width: calc(100% - 285px);
            }
            @media (max-width: $mob) {
                width:100%;
            }
        }
        .content__aside{
            width:290px;
            @media (max-width: $tab) {
                width: 260px;
            }
            @media (max-width: $mob) {
                width:100%;
                margin-bottom:25px;
            }
            .aside__info{
                position: sticky;
                top:20px;
                padding:56px 54px;
                background:$bg-contrast;
                @media (max-width: $tab) {
                    padding: 16px 20px;
                }
                .info__line{
                    margin-bottom:13px;
                    padding-bottom:13px;
                    border-bottom:1px solid $grey-light;
                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                    &--title {
                        font-size: 22px;
                        font-weight: 500;
                    
                        @media (max-width: $tab) {
                            font-size: 19px;
                        }
                    
                        @media (max-width: $mob) {
                            display: none;
                        }
                    }
                    .line__share{
                        font-size: 19px;
                        font-weight: 600;
                        @media (max-width: $tab) {
                            font-size: 17px;
                        }
                    }
                }

            }
        }
    }
}