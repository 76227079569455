.block-video{
    .block-video__content{
        max-width:800px;
        margin:auto;
        border: 3px solid $orange;
        border-radius: 28px;
        overflow: hidden;
        video{
            display:block;
            width:100%;
        }
    }

}