.gouvernance{
    .gouvernance__title{
        margin-top:32px;
        font-size: 18px;
        font-weight:700;
    }
    .gouvernance__list{
        padding: 0;
        list-style: none;
        .list__item {
            font-size: 18px;
            a{
                color:$black;
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
        
    
}