.block-presentation{
    position:relative;
    height:100%;
    box-sizing:border-box;
    padding:30px 30px 50px 30px;
    background:$bg-footer;
    border-radius:19px;
    @media (max-width: $mob) {
        padding: 30px 15px 50px 15px;
    }
    .block-presentation__content{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; 
        margin-top:30px;
        @media (max-width: $mob) {
           flex-direction: column;
        }
        .content__card{
            width:calc(50% - 5px);
            @media (max-width: $mob) {
                width: 100%;
            }
        }
    }
    .block-presentation__link{
        position:absolute;
        bottom:30px;
        left:0;
        width:100%;
        padding:0 15px;
        box-sizing: border-box;
    }
}