.title-programme-idee{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    max-width:425px;
    margin-left:50px;
    @media (max-width: $tab) {
        max-width:none;
        margin: 0 0 30px 0;
    }
    @media (max-width: $mob) {
        margin: 0 0 20px 0;
    }
    .title-programme-idee__title{

    }
    .title-programme-idee__img {
        @media (max-width: $tab) {
            display:none;
        }
    }
}