.filters{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap:18px;
    .filters__title{
        font-size:21px;
        font-weight:500;
    }
    .filters__reinit{
        cursor: pointer;
    }
    .filters__item{
        position: relative;
        .item__input{
            opacity: 0;
            visibility: hidden;
            position:absolute;
            &:checked + .item__label{
                &:after{
                    content: "\f00d";
                    margin-left: 10px;
                    font-family: "Font Awesome\ 6 Free";
                    font-weight: 900;
                    transform: translateX(7px);
                }
            }
        }
        .item__label{
            position:relative;
            display: inline-flex;
            padding: 4px 18px;
            background: $white;
            border-radius:  5px ;
            color: $black;
            border:1px solid $black;
            font-size: 17px;
            font-weight: 500;
            cursor: pointer;
        }
    }
}