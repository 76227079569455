.title{
    font-weight: 500;
    font-size: 32px;
    color:$grey-dark;
    @media (max-width: $mob) {
        font-size: 32px;
    }
    &--m{
        font-size:32px;
        @media (max-width: $mob) {
            font-size: 28px;
        }
    }
    &--s{
        font-size: 28px;
        @media (max-width: $mob) {
            font-size: 24px; 
        }
    }
    &--xs {
        font-size: 22px;
        @media (max-width: $mob) {
            font-size: 20px;
        }
    }
}