.simulateur{
    .question-item{
        padding-top:160px;
        @media (max-width: $tab) {
            padding-top: 100px;
        }
        &--no-padding-top{
            padding-top:0;
        }
        .question-item__title{
            font-size: 29px;
            font-weight: 500; 
            text-align:center;
            @media (max-width: $mob) {
                font-size: 25px;
            }
        }
        .question-item__helper{
            margin-top:25px;
            background: $bg-footer;
            .helper__header{
                display: flex;
                justify-content: center;
                align-items: center;
                height:60px;
                color:$orange;
                font-size:18px;
                cursor:pointer;
                &.active{
                    .header__down {
                        transform: rotate(180deg);
                    }
                }
                .header__down{
                    margin-left:10px;
                    transition: all 0.2s;
                }
            }
            .helper__content{
                //display:none;
                padding:0 50px 10px 50px;
                text-align:center;
                @media (max-width: $mob) {
                    padding: 0 15px 10px 15px;
                }
            }
        }
        .question-item__text{
            margin-top: 40px;
        }
        .question-item__helper-2{
            max-width:800px;
            margin: 30px auto 0 auto;
            p{
                font-size:18px;
                text-align:center;
            }
        }
        .question-item__answer{
            display: flex;
            justify-content: center;
            gap: 0 36px;
            margin-top:40px;
            @media (max-width: $tab) {
                gap: 0 20px;
            }
            @media (max-width: $mob) {
                flex-direction: column;
                gap:20px;
            }
            .answer__button{
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width:100%;
                max-width: 510px;
                min-height:250px;
                box-sizing: border-box;
                padding:10px 18px 30px 18px;
                border:1px solid $orange;
                border-radius:28px;
                font-size: 27px;
                font-weight: 500;
                text-align: center;
                cursor:pointer;
                transition: all 0.2s;
                @media (max-width: $tab) {
                    padding: 10px 10px 30px 10px;
                }
                @media (max-width: $mob) {
                    max-width: none;
                    height: auto;
                    min-height: 150px;
                    padding: 20px 10px 40px 10px;
                    font-size: 24px;
                }
                &:before{
                    content: "";
                    position: absolute;
                    bottom:20px;
                    left: calc(50% - 10px);
                    width:18px;
                    height:18px;
                    background: $white;
                    border: 1px solid $orange;
                    border-radius: 20px;
                    @media (max-width: $mob) {
                        bottom: 15px;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 24px;
                    left: calc(50% - 6px);
                    width: 12px;
                    height: 12px;
                    background: $orange;
                    border-radius: 20px;
                    opacity: 0;
                    transition: all 0.2s;
                    @media (max-width: $mob) {
                        bottom: 19px;
                    }
                }
                &.active{
                    background:$orange;
                    color:$white;
                    &:after {
                        opacity: 1;
                    }
                }
                &--input{
                    
                    @media (max-width: $mob) {
                        padding-bottom: 20px;
                    }
                    cursor:default;
                    &:before {
                        display:none;
                    }
                
                    &:after {
                        display: none;
                    }
                    .answer__input{
                        margin-top:15px;
                        .input__button {
                            margin-top: 23px;
                        }
                    }
                    &.active{
                        .button{
                            background:$white;
                            color:$orange;
                        }
                    }
                }
                .button__mentions{
                    margin-top:15px;
                    font-size:16px;
                    font-weight: 400;
                    @media (max-width: $mob) {
                        margin-top: 10px;
                    }
                }
            }
            .answer__input{
                text-align: center;
                .input__description{
                    margin-bottom: 20px;
                    font-size:18px;
                    font-weight:normal;
                    text-align:center;
                }
                .input__select{
                    width: 100%;
                    max-width: 360px;
                    height: 40px;
                    background: $white;
                    border: 1px solid $orange;
                    border-radius: 6px;
                    text-align: center;
                    color: $orange;
                    font-family: $font-1;
                    font-size: 18.25px;
                    font-weight: 600;
                    transition: all 0.2s;
                    outline: none;
                }
                .input__input{
                    width:100px;
                    height:40px;
                    background: $white;
                    border:1px solid $orange;
                    border-radius:6px;
                    text-align:center;
                    color:$orange;
                    font-family: $font-1;
                    font-size: 18.25px;
                    font-weight: 600;
                    transition: all 0.2s;
                    outline: none;
                    &::placeholder {
                        color: $orange;
                        opacity: 1;
                        /* Firefox */
                    }
                
                    &::-ms-input-placeholder {
                        /* Edge 12 -18 */
                        color: $orange;
                    }
                    &:focus{
                        //border-color: $green;
                    }
                }
                .input__unit{
                    font-size: 18.25px;
                }
                .input__button{
                    margin-top:30px;
                    text-align: center;
                }
            }
        }
        .question-item__results{
            display: flex;
            justify-content: space-between;
            margin-top:30px;
            @media (max-width: $mob) {
               flex-direction: column-reverse;
               gap:20px;
            }
            .results__table{
                width:calc(50% - 15px);
                border:1px solid $orange;
                border-radius: 28px;
                border-spacing: 0;
                font-size:16px;
                overflow: hidden;
                @media (max-width: $tab) {
                    width: calc(50% - 10px);
                }
                @media (max-width: $mob) {
                    width: 100%;
                }
                tr{
                    th{
                        height:46px;
                        background:$orange;
                        border: 3px solid $bg-footer;
                        border-top:0;
                        text-align: center;
                        color:$white; 
                        &:first-child {
                            border-left: 0;
                        }
                    
                        &:last-child {
                            border-right: 0;
                        }
                    }
                    td{
                        width:50%;
                        min-width:145px;
                        min-height: 52px;
                        padding:15px;
                        box-sizing: border-box;
                        border:3px solid $bg-footer;
                        text-align:center;
                        &:first-child{
                            border-left:0;
                        }
                        &:last-child {
                            border-right: 0;
                        }
                        input, select{
                            appearance: none;
                            width:84px;
                            height:23px;
                            background: $bg-footer;
                            border:0;
                            text-align:center;
                            font-family:$font-1;
                            font-size: 16px;
                            font-weight: 500;
                            color:$orange;
                            &:focus{
                                outline-color: $orange;
                            }
                            -moz-appearance: textfield;
                        }
                        .table__input-percent{
                            position: absolute;
                            transform: translateX(-17px) translateY(2px);
                        }
                        img{
                            display: inline;
                            margin-left: 10px;
                            width:13px !important;
                            height:13px !important;
                        }
                        .td-info{
                            position:relative;
                            max-width: 500px;
                            background: $bg-footer;
                            padding: 10px 5px;
                            border-radius: 10px;
                            color:$orange;
                            .td-info__close{
                                position:absolute;
                                top:-9px;
                                right:-5px;
                                cursor:pointer;
                            }
                        }
                        
                    }
                    &:first-child{
                        td {
                            border-top: 6px solid $bg-footer;
                        }
                    }
                    &:last-child {
                        td {
                            border-bottom: 0;
                        }
                    }
                }
            }
            .results__results{
                display: flex;
                justify-content: center;
                align-items: center;
                width: calc(50% - 15px);
                padding:40px 20px;
                box-sizing: border-box;
                background: $bg-footer;
                border-radius: 28px;
                font-size:18px;
                text-align:center;
                @media (max-width: $tab) {
                    width: calc(50% - 10px);
                }
                @media (max-width: $mob) {
                    width: 100%;
                }
                .results__content{
                    max-width: 430px;
                    .content__number {
                        margin-top: 30px;
                        font-size: 56px;
                        font-weight: 600;
                        color:$orange;
                    }
                    .content__units {
                        font-weight: 600;
                        font-size: 26px;
                        color: $orange;
                    }
                    .content__comprenant{
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}


.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    overflow: hidden;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
    height: auto;
    padding-top: 16px;
    /* Adjust according to your design */
    padding-bottom: 16px;
    /* Adjust according to your design */
}

.helper__header {
    cursor: pointer;
}