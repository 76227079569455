.ressources-jpal{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .ressources-jpal__item{
        width: calc(50% - 55px);
        margin:34px 0;
        @media (max-width: $desksm) {
            width: calc(50% - 25px);
        }
        @media (max-width: $tab) {
            width: calc(50% - 15px);
        }
        @media (max-width: $mob) {
            width:100%;
        }
        .item__content{
            .content__top{
                position: relative;
                border: 3px solid $orange;
                border-radius: 22px;
                overflow: hidden;
                .top__img{
                    width: 100%;
                    height: 260px;
                    object-fit: cover;
                    @media (max-width: $mob) {
                        height: 230px !important;
                    }
                }
                .top__title{
                    position: absolute;
                    top:0;
                    left:0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width:100%;
                    height:100%;
                    background:rgba($black, 0.6);
                    h2{
                        width:calc(100% - 40px);
                        padding:20px;
                        font-size: 30px;
                        font-weight: 500;
                        color:$white;
                        text-align:center;
                        @media (max-width: $tab) {
                            font-size: 24px;
                        }
                    }
                }
            }
            .content__text{
                max-width:420px;
                margin: 15px auto 0 auto;
                text-align:center;
                .text__title{
                    font-size:19px;
                    font-weight:500;
                    color:$orange;
                }
                .text__link{
                    margin-top:10px;
                    .link-black{
                        font-weight: normal !important;
                    }
                }
            }
        }
    }
}